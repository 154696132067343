import React from 'react';
import Button from '@material-ui/core/Button';
import {navigate} from "gatsby"
import Layout from "../components/layout";
import Grow from '@material-ui/core/Grow';

import SEO from "../components/seo"

function handleClick() {
  navigate('/')
}

function NotFoundPage() {
  // Render
  return (<Layout>
    <SEO title = "Oops" />
    <div style={{
        backgroundColor: '#fcfcfc',
        height: '98vh',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '20px'
      }}>
      <Grow in="in">
        <div style={{
            backgroundColor: '',
            height: '100vh',
            width: '80vw',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
          <h1 style={{
              textAlign: 'center',
              fontFamily: 'Work Sans',
              fontSize: '40px'
            }}>Oops, there's no page at this address.</h1>
          <Button onClick={handleClick} style={{
              marginTop: '4vh'
            }} size="large" variant='outlined' color="primary">Go Back Home</Button>
        </div>
      </Grow>
    </div>
  </Layout>)
}

export default NotFoundPage
